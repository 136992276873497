/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Aš galvoju, kad reikia skėčius prijungt prie interneto ir siųst visus\nduomenis vyriausybei. Raportuot kiek kritulių krenta kiekvienoje miesto\nvietoje. Ir šiaip – būtų neblogai primontuot prie jų dar mikrofonus ir\nspeaker’ius, kad būtų galima kiekvieno pokalbio klausytis ir juos\nįrašinėti. Nu taip sau, visai ne dėl to, kad norėčiau ką nors\nkontroliuoti."), "\n", React.createElement(_components.p, null, "Įdomios mintys, žmogau, kyla, kol spoksai į žmones bandydamas susirašyti\n„parūkymo įvalgas“: moteriškė galinėjosi eidama tiltu su savo skėčiu ir\naš pagalvojau, kad jam verkiant reikia mikrofonų ant kiekvienos jo\nšakos. O speaker’ius pridėti reiktų tik tam, kad prijungus gudraskėtį\nprie gudrafono galėtum „skambyt“ trijose dimensijose bet kam, kas irgi\nnaudoja šitą skėtį. Dar jeigu į to skėčio rankeną įdėti drėgmės\nmatuoklius (tokius, kokie būna (būdavo?) melo detektoriuose) ir, jei tik\nįmanoma, pridėti dar ir spaudimo (tactile) sensorius – būtų galima\nmatuoti kiek pokalbis intensyvus ar jaudinantis. Way cool būtų sekti\nšituos duomenis ir ne pokalbio metu: būtų galima žymėti labiausiai\njaudinančias ar stimuliuojančias vietas mieste. Su visais ten tampomais\nžemėlapiais ir reviews’ais. Visiškai nereikalingas daiktas, bet kaip\nmeno projektas – gali būti visai įdomus."), "\n", React.createElement(_components.p, null, "Esu girdėjęs apie skandinavus, kurie darė ", React.createElement(_components.a, {
    href: "http://auctionbasedonemotions.com/en/"
  }, "aukcioną, based on\nemotion"), ": be jokios valiutos ir\natsiskaitymo – meno darbai atiteko žmonėms, kuriuos tie meno darbai\nlabiausiai stimuliavo. Iš esmės tai flawed konceptas, nes žmonių emocijų\ntaip lengvai nepamatuosi ir nenustatysi kas jas kelia, bet įjungus\nsuspension of disbelief – visai įdomu. Check kaip jie tai padarė."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Viskas mano gyvenime dabar sukasi apie dalinimąsi to, ką nuveikiau per\ntam tikrą laiko tarpą: periodinės ataskaitos. Tik man tai yra paprasta –\naš pratęs naudotis HTML generuokliais, ir šiaip – apžymėti turinį ranka\nir jam parašyti stilius man atrodo labai smagus užsiėmimas. Kitiems –\nsunkiau. Net jei jiems tai ir būtų įdomu, reiktų šio bei to išmokti, o\ntam reikia laiko. Laiko, kurio dažnas maker’is neturi. Taip gimė\nmakerspace ", React.createElement(_components.a, {
    href: "https://github.com/makerspacelt/sandelis/wiki"
  }, "proceso rodymo\nįrankio"), " mintis: web\napp’as, kuris palengvintų darbą su wiki rašymu, markup’u ir stiliais.\nSuprask, dienoraštis ar žurnalas, kuriame žymima kas, kada ir kaip buvo\nįvykdyta, leidžiant tai skaityti publikai. Publika, savo ruožtu, galėtų\nmatyti kaip galima dalintis mintimis (ir kiek iš to gero išeina), galėtų\nkurti savus projektus ir juos aptarinėti. Aš dažnai bėdavoju, kad\nbendraminčiai neprieina ir nesiūlo kolaboruotis. Tai yra bendra maker’ių\nir makerspace’ų problema: makerspace.lt buvo tikriausiai pirmasis\nspace’as Lietuvoje. Buvo jų ir iki tol, bet iki šito space’o atidarymo\njie buvo nustoję veikti. Dabar turim bent du Vilniuje ir bent vieną\nKaune. Iš jų tik vienas glaudžiasi po makerspace.lt vėliava. Kiekvienas\ndirba sau ir savo nariams be jokio noro kolaboruotis rodymo. Nežinau\nkaip tai spręsti, bet vidinė maker’ių problema galėtų būti apmažinama\npaleidus šitą įrankį ir leidžiant prisijungti visiems. Plačiau apie\nmintį paskaityt galima\n", React.createElement(_components.a, {
    href: "https://github.com/makerspacelt/sandelis/wiki"
  }, "wiki"), " ir diskusijoj\nsudalyvauti ", React.createElement(_components.a, {
    href: "https://github.com/makerspacelt/sandelis/issues"
  }, "aptarime"), ".\nJei yra noras šiaip pasišnekėti – ", React.createElement(_components.a, {
    href: "irc://chat.freenode.net/#kaunas-makerspace"
  }, "visada\nprašom"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
